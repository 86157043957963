<template>
  <!-- Main content -->
  <section class="content">
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加</el-button>
    </div>
    <!-- 表单 -->
    <el-table 
        v-loading="loading"
        :data="diamondList"
        border
        style="width: 100%;">
        <el-table-column fixed label="编号" prop="id" width="50"></el-table-column>
        <el-table-column fixed label="入口名称" prop="name" width="120"></el-table-column>
        <el-table-column  label="入口图片" width="120">
            <template slot-scope="item">
                <el-image :src="item.row.icon_url" :preview-src-list="[item.row.icon_url]"></el-image>
            </template>
</el-table-column>
<el-table-column label="跳转类型">
    <template slot-scope="item">
        {{item.row.redirect_type | redirect}}
    </template>
</el-table-column>
<el-table-column label="安卓跳转链接" prop="android_redirect_url" width="250">
    <template slot-scope="item">
        {{item.row.android_redirect_url}}
        <el-button type="text" size="small" v-clipboard:copy=item.row.android_redirect_url v-clipboard:success="copy" >复制</el-button>
    </template>
</el-table-column>
<el-table-column label="跳转标题" prop="title"></el-table-column>
<el-table-column label="权重" prop="weight"></el-table-column>
<el-table-column label="状态">
    <template slot-scope="item">
        {{item.row.status | status}}
    </template>
</el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
            <el-button type="primary" size="mini" @click="getRest(item.row.id)">修改</el-button>
            <!-- <el-button type="danger" size="mini" @click="">删除</el-button> -->
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>
<!-- 修改添加弹窗 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="入口名称">
            <el-input v-model="name" placeholder="请输入入口名称"></el-input>
        </el-form-item>
        <el-form-item label="入口图片">
            <upLoadText @url="imgUrl" id="up" v-model="icon_url"></upLoadText>
        </el-form-item>
        <el-form-item label="跳转类型">
            <el-select v-model="redirect_type" placeholder="">
                <el-option v-for="item in redirectList" :label="item.name" :value="item.type"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="安卓跳转链接">
            <el-input v-model="android_redirect_url" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="IOS跳转链接">
            <el-input v-model="ios_redirect_url" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="携带参数">
            <el-input v-model="bind_params" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="跳转标题">
            <el-input v-model="title" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="权重">
            <el-input v-model="weight" type="number" placeholder=""></el-input>
        </el-form-item>



        <el-form-item label="是否显示">
            <el-radio-group v-model="status">
                <el-radio v-for="item in statusList" :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="版本限制">
            <el-radio-group v-model="version_type">
                <el-radio v-for="item in compareList" :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="版本号">
            <el-input v-model="version" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="审核模式是否显示">
            <el-radio-group v-model="is_show">
                <el-radio label="1">显示</el-radio>
                <el-radio label="0">隐藏</el-radio>
            </el-radio-group>
        </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem('common'))
    import * as api from "@/config/api";
    export default {
        name: 'maintenanceRest',
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                redirectList: common.redirect_type_list,
                compareList: common.compare_type_list,
                groupList: common.user_group_list,
                behaviorList: common.user_behavior_list,

                statusList: [{
                    type: "1",
                    name: '是'
                }, {
                    type: "2",
                    name: '否'
                }],

                dialog: false,
                diamondList: [],

                search_id: '',


                id: '',
                name: '',
                icon_url: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
                bind_params: '',
                title: '',
                weight: '',
                status: '',
                version_type: '',
                version: '',
                group_id: '',
                day_number: '',
                behavior_type: '',
                is_show: '',
                start_time: "",
                end_time: "",
                specific_time: [
                    ['', '']
                ],
            }
        },
        filters: {
            redirect(val) {
                let name = ''
                if (JSON.stringify(common.redirect_type_list).indexOf(val) != -1) {
                    common.redirect_type_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return '显示'
                        break;
                    case 2:
                        return '隐藏'
                        break;
                    default:
                        break;
                }
            }
        },
        mounted() {
            this.getRestList()
        },
        methods: {
            //   删除特定时间
            del_specific_time_item(index) {
                this.specific_time.splice(index, 1);
            },
            //   添加特定时间
            add_td_tiem() {
                this.specific_time.push(['', ''])
            },
            // 获取列表
            getRestList(page, size) {
                api.getRestList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                    page_id: this.search_id
                }, res => {
                    this.diamondList = res.data.list
                    this.total = Number(res.data.count)
                })
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val
                this.getRestList()
            },
            currentChange(val) {
                this.page = val
                this.getRestList()
            },
            //搜索
            seach(page) {
                this.page = page
                this.getRestList(page)
            },
            reset(page) {
                this.search_id = ''
                this.page = page
                this.getRestList(page)
            },
            // 复制
            copy() {
                this.$message({
                    message: '复制成功',
                    type: 'success'
                })
            },
            // 上传图片
            imgUrl(val) {
                this.icon_url = val
            },
            getTime(time) {
                let Time = new Date(time * 1000);
                return `${Time.getFullYear()}-${Time.getMonth() + 1 < 10 ? "0" + (Time.getMonth() + 1) : Time.getMonth() + 1}-${Time.getDate() < 10 ? "0" + Time.getDate() : Time.getDate()} ${Time.getHours() < 10 ? "0" + Time.getHours() : Time.getHours()}:${Time.getMinutes() < 10 ? "0" + Time.getMinutes() : Time.getMinutes()}:${Time.getSeconds() < 10 ? "0" + Time.getSeconds() : Time.getSeconds()}`
            },
            // 获取详情
            getRest(id) {
                this.id = id
                api.getRest({
                    id: id
                }, res => {
                    console.log(res.data.group_id)
                    this.name = res.data.name
                    this.icon_url = res.data.icon_url
                    this.redirect_type = res.data.redirect_type
                    this.android_redirect_url = res.data.android_redirect_url
                    this.ios_redirect_url = res.data.ios_redirect_url
                    this.bind_params = res.data.bind_params
                    this.title = res.data.title
                    this.weight = res.data.weight
                    this.status = res.data.status
                    this.version_type = res.data.version_type
                    this.start_time = Number(res.data.start_time) != 0 ? this.getTime(Number(res.data.start_time)) : '';
                    this.end_time = Number(res.data.end_time) != 0 ? this.getTime(Number(res.data.end_time)) : '';
                    this.version = res.data.version
                    this.group_id = res.data.group_id != '' ? res.data.group_id.split(',') : ''
                    this.day_number = res.data.day_number
                    this.behavior_type = res.data.behavior_type
                    this.is_show = res.data.is_show
                    this.dialog = true
                    let specific_time = res.data.specific_time;
                    let specific_time_arr = [];
                    if (specific_time) {
                        specific_time.forEach((item, index) => {
                            specific_time_arr.push([item.start_time, item.end_time]);
                        });
                        this.specific_time = specific_time_arr;
                    }
                })
            },
            // 添加选品
            add() {
                this.id = ''
                this.name = ''
                this.icon_url = ''
                this.redirect_type = ''
                this.android_redirect_url = ''
                this.ios_redirect_url = ''
                this.bind_params = ''
                this.title = ''
                this.weight = ''
                this.status = ''
                this.version_type = 0
                this.version = ''
                this.group_id = ''
                this.day_number = ''
                this.behavior_type = ''
                this.is_show = ''
                this.dialog = true
                this.specific_time = [
                    ['', '']
                ];
                this.start_time = "";
                this.end_time = "";
            },
            // 修改、添加频道分类
            alter() {
                let specific_time = [];
                this.specific_time.forEach(item => {
                    specific_time.push({
                        start_time: item[0],
                        end_time: item[1],
                    })
                })
                if (this.id != '') {
                    api.updateRest({
                        id: this.id,
                        name: this.name,
                        icon_url: this.icon_url,
                        redirect_type: this.redirect_type,
                        android_redirect_url: this.android_redirect_url,
                        ios_redirect_url: this.ios_redirect_url,
                        bind_params: this.bind_params,
                        title: this.title,
                        weight: this.weight,
                        status: this.status,
                        version_type: this.version_type,
                        version: this.version,
                        group_id: this.group_id,
                        day_number: this.day_number,
                        behavior_type: this.behavior_type,
                        is_show: this.is_show,
                        specific_time,
                    }, res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialog = false
                        this.getRestList()
                    })
                    return
                }
                api.addRest({
                    name: this.name,
                    icon_url: this.icon_url,
                    redirect_type: this.redirect_type,
                    android_redirect_url: this.android_redirect_url,
                    ios_redirect_url: this.ios_redirect_url,
                    bind_params: this.bind_params,
                    title: this.title,
                    weight: this.weight,
                    status: this.status,
                    version_type: this.version_type,
                    version: this.version,
                    group_id: this.group_id,
                    day_number: this.day_number,
                    behavior_type: this.behavior_type,
                    is_show: this.is_show,
                    specific_time
                }, res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.dialog = false
                    this.getRestList()
                })
            },
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>